function getSectionsToRender() {
  return [
    {
      id: 'cart-icon-bubble',
      section: 'cart-icon-bubble',
    },
    {
      id: 'cart-sidebar-items',
      section: 'cart-sidebar-items',
    },
    {
      id: 'cart-sidebar-footer',
      section: 'cart-sidebar-footer',
    },
  ];
}

function getSectionInnerHTML(html, selector = '.shopify-section') {
  return new DOMParser().parseFromString(html, 'text/html').querySelector(selector).innerHTML;
}

function updateSections(source = {}) {
  const cartSidebar = window.document.getElementById('cart-sidebar');

  if (cartSidebar) {
    cartSidebar.dataset.status = 'open';
  }

  getSectionsToRender().forEach(({ id, section, selector }) => {
    const tagretSection = document.getElementById(id),
      targetElement = tagretSection.querySelector(selector) || tagretSection;
    targetElement.innerHTML = getSectionInnerHTML(source[section], selector);
  });
}

export function addToCart(evt) {
  evt.preventDefault();

  const form = evt.currentTarget;
  const submitButton = form.querySelector('[type="submit"]');

  submitButton.setAttribute('disabled', true);
  submitButton.classList.add('loading');

  const config = {
    method: 'POST',
    headers: {
      'Accept': `application/javascript`,
      'X-Requested-With': 'XMLHttpRequest',
    },
  };

  const formData = new FormData(form);

  formData.append('sections', 'cart-icon-bubble,cart-sidebar-items,cart-sidebar-footer');
  formData.append('sections_url', window.location.pathname);
  config.body = formData;

  fetch('/cart/add', config)
    .then((response) => response.json())
    .then((parsedState) => {
      if (parsedState.status) {
        return;
      }
      updateSections(parsedState.sections);
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      submitButton.classList.remove('loading');
      submitButton.removeAttribute('disabled');
    });
}

export function onContentClick() {
  const cartSidebar = window.document.getElementById('cart-sidebar');
  const content = window.document.querySelector('main');

  if (cartSidebar) {
    content.addEventListener('click', () => {
      cartSidebar.dataset.status = 'close';
    });

    content.addEventListener('touchmove', () => {
      cartSidebar.dataset.status = 'close';
    });
  }
}
